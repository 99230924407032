.main {
  padding: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}
.wrapper {
  width: 100%;
  max-width: 406px;
  margin: 0 auto;
}
.logo {
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
   margin-bottom: 25px; 
}
.head {
  margin-bottom: 40px;
}
.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #1A1F36;
  text-align: center;
  margin-bottom: 20px;
}
.subTitle {
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  color: #202020;
  text-align: center;
}
.codeSent {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #202020;
  text-align: center;
}