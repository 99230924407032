
.ThemeFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #4D1B6C;
  padding: 12px 55px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9;
}
.BtnSave {
  min-width: 127px;
  color: #4D1B6C;
  background-color: #fff;
  min-height: 35px;
}
.DarkDisabled[disabled] {
    opacity: 0.5 !important; 
    cursor: default!important;
    pointer-events: none;
    background: #fff;
    color: #000;
}
.BtnSave:hover,
.BtnSave:focus,
.BtnSave:active {
  color: #4D1B6C !important;
  background-color: #fff !important;
}

.editor-instance {
  position: relative;
  .ck-content {
    border: 2px solid #E5E5E5 !important;
    border-top: 0 !important;
    min-height: 500px;
    border-radius: 4px !important;
    &.ck-focused {
      box-shadow: none !important;
    }
  }
}

.editor-instance-new {
  position: relative;
  .ck-content {
    border: 2px solid #E5E5E5 !important;
    border-top: 0 !important;
    min-height: 50px;
  	height:60px;
    border-radius: 4px !important;
    &.ck-focused {
      box-shadow: none !important;
    }
  }
}
