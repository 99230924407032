@import "./variable.scss";
@import "./fonts.scss";

// Bootrsrap Overwrite CSS
@media (min-width: 1200px) {
  .container {
    max-width: $maxWidth;
  }
}

.custom__container {
  width: calc(100% - 30px);
  max-width: $maxWidth;
  margin: 0 auto;
}

a {
  color: #030dcf;
}

.ck__editor--customized {
  .ck {
    &.ck-toolbar {
      border: 1px solid #D9DCE1;
      background: $white;
      border-radius: 4px 4px 0 0 !important;
    }
  }

  .ck-editor__editable {
    border: 1px solid #D9DCE1 !important;
    border-top: 0 !important;
    border-radius: 0 0 4px 4px !important;
    min-height: 300px;
    max-height: 300px;

    &:focus {
      box-shadow: none !important;
    }
  }
}

// Bootsrap overwrite

hr {
  border-color: var(--light-gray);
  opacity: 1;
}

.form-group {
  margin-bottom: 22px;

  &.inline-form-row {
    display: flex;
    justify-content: space-between;
  }
}

.ReactPhoneNumberInput {
  &.PhoneInput {
    border: 1px solid #000;
  }

  .PhoneInputCountry {
    background: #fcbe99;
    width: 85px;
    justify-content: center;
    margin-right: 0;
    border-right: 1px solid #000;

    .PhoneInputCountrySelectArrow {}
  }

  .PhoneInputInput {
    min-height: 60px;
    border: 0;
    padding: 12px 15px;

    &:focus {
      outline: 0;
    }
  }
}

.form-control {
  border: 1px solid #D9DCE1;
  border-radius: 0.3125rem;
  color: $black;
  font-size: $fontbase;
  min-height: calc(1.5em + 0.75rem + 0.5rem);

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &:focus {
    box-shadow: none;
    border-color: $gray;
  }

  &.form-control-flat {
    border-radius: 0;
  }
}


.PhoneInputCountrySelectArrow {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  vertical-align: middle;
  transform: rotate(45deg);
}

.PhoneInputCountryIcon {
  margin-right: 4px;
}

.international-phone {
  position: relative;
  padding: 0;
  display: flex;
  overflow: hidden;
  border: 1px solid #D9DCE1;
  border-radius: 0.3125rem;
  min-height: calc(1.5em + 0.75rem + 0.5rem);
}

.PhoneInput--focus {
  input {
    box-shadow: none;
    border-color: transparent;
    outline: none;
  }
}

.international-phone .PhoneInputCountry {
  position: relative;
  padding: 5px;
  top: 0;
  left: 0;
  background: #4d1b6c;
  width: 80px;
  display: flex;
  // height: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 0.3125rem 0 0 0.3125rem;
}

.international-phone select {
  border: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.international-phone .PhoneInputCountryIconImg {
  width: 20px;
}

.international-phone .PhoneInputInput {
  width: 100%;
  padding: 5px;
  border: none;
  height: 44px;

  &:focus {
    box-shadow: none;
    border-color: transparent;
  }
}




textarea.form-control {
  padding: 0.9375rem 0.75rem;
  resize: none;
}

select,
select.custom-select {
  appearance: none;
  background: $white url(../images/select-icon.svg) no-repeat;
  background-position: calc(100% - 15px) center;
  padding-right: 30px;
  cursor: pointer;
}

.form-control-note {
  font-style: italic;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 16px;
  color: #979797;
  margin-top: 0.3125rem;
}

.form-control-normal-note {
  font-size: 14px;
  line-height: 17px;
  color: #1A1F36;
}

.invalid-feedback {
  display: block;
  // font-size: 14px;
  color: $red;

  &:empty {
    display: none;
  }
}

.success-feedback {
  display: block;
  font-size: 14px;
  color: $green;
  width: 100%;
  margin-top: 0.25rem;

  &:empty {
    display: none;
  }
}

.label-primary {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1A1F36;
  margin-bottom: 10px;

  .required {
    color: $red;
  }
}

.label-secondary {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 6px;

  .required {
    color: $red;
  }

  &.dark {
    color: $white;
  }
}

.btn {
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0.375rem 0.5rem;
  border-radius: 0.3125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    box-shadow: none;
    opacity: .9;
  }

  &:disabled {}
}

.btn__icon {
  margin-right: 0.5rem;
}

.btn-lg {
  font-size: 1rem;
  padding: 0.6875rem 1rem;
}

.tab_btn--container {
  margin-top: 15px;
}

.tab__btn {
  color: var(--text-gray);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  padding: 5px 23px;
  border-radius: 100px;
  border: none;
  background-color: transparent;

  &.active {
    background: rgba(77, 27, 108, 0.1);
    color: var(--purple);
  }
}

.btn__purple {
  color: $white;
  background: $purple;
  border-color: $purple;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #4D1B6C, 0px 1px 1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background: $purple;
    border-color: $purple;
  }
}

.btn__orange {
  color: $white;
  background: #ec4e0f;
  border-color: #ec4e0f;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px #ec4e0f, 0px 1px 1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  &:hover {
    color: $white;
    background: #ec4e0f;
    border-color: #ec4e0f;
  }

  &:focus,
  &:active {
    color: $white;
    background: $purple;
    border-color: $purple;
  }
}

// .btn__orange {
//   color: $white;
//   background: var(--orange);
//   border-color: var(--orange);
//   box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
//   border-radius: 4px;

//   &:hover,
//   &:focus,
//   &:active {
//     color: $white;
//     background: var(--orange);
//     border-color: var(--orange);
//   }
// }
.btn__green {
  color: $white;
  background: $secondaryColor;
  border-color: $secondaryColor;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background: $secondaryColor;
    border-color: $secondaryColor;
  }
}

.btn__purple--outline {
  color: $pink;
  background: $white;
  border-color: $white;

  &:hover,
  &:focus,
  &:active {
    color: $pink;
    background: $white;
    border-color: $white;
  }
}


.btn__default {
  color: #3C4257;
  background: $white;
  border-color: $white;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
    0px 0px 0px 1px rgba(60, 66, 87, 0.16),
    0px 1px 1px rgba(0, 0, 0, 0.12);

  &:hover,
  &:focus,
  &:active {
    color: #3C4257;
    background: $white;
    border-color: $white;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
      0px 0px 0px 1px rgba(60, 66, 87, 0.16),
      0px 1px 1px rgba(0, 0, 0, 0.12);
  }
}

.btn__link {
  color: $pink;
  text-decoration: none;
  padding: 0 0.5rem;

  &:hover,
  &:focus,
  &:focus {
    color: $pink;
  }
}

.btn__link--underline {
  color: $pink;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:focus {
    color: $pink;
    text-decoration: underline;
  }
}

.btn__link--deafult {
  color: $black;
  text-decoration: none;

  &:hover,
  &:focus,
  &:focus {
    color: $black;
    text-decoration: none;
  }
}

.btn__link--danger {
  color: $red;
  text-decoration: none;

  &:hover,
  &:focus,
  &:focus {
    color: $red;
    text-decoration: none;
  }
}

.btn__delete--icon {
  color: var(--delete);
  text-decoration: none;

  &:hover,
  &:focus,
  &:focus {
    color: var(--delete);
    text-decoration: none;
  }
}

.btn__text {
  color: var(--purple);
  padding: 0;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.card {
  border: 0;
  box-shadow: 5px 5px 20px #E5E5E5;
  border-radius: 6px;
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }

  &.none-shadow {
    box-shadow: none;
  }

  &.transparent {
    background-color: transparent;
  }

  .card-header {
    padding: 12px 25px;
    background-color: #fff;
    border-bottom: 1px solid #E5E5E5;
    min-height: 65px;
    border-radius: 6px 6px 0 0;

    &.card-header-lg {
      padding: 12px 45px;

      @media (max-width: 767px) {
        padding: 12px 15px;
      }
    }

    .card-title {
      font-weight: $bold;
      font-size: 22px;
      line-height: 26px;
      color: $black;
      margin: 0;

      @media (max-width: 767px) {
        font-size: 18px;
      }

      &.card-title-sm {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .card-body {
    padding: 20px 45px;

    @media (max-width: 767px) {
      padding: 20px 15px;
    }

    &.card-body-sm {
      padding: 25px;

      @media (max-width: 767px) {
        padding: 15px;
      }
    }
  }
}

// Bootsrap overwrite

// Base 
.cursor {
  cursor: pointer;
}

.underline {
  text-decoration: underline !important;
}

// Common Css

// Common Css

// Loader
.anticon-spin {
  animation: loadingCircle .6s infinite linear;
}

.loader__full {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 10;

  &::after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  &.loader__cover {
    position: absolute;
  }
}

.loader-container {
  height: 100%;
  width: 100%;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Loader

// Slick orverwrite
.slick-arrow {
  z-index: 1;

  &.slick-prev,
  &.slick-next {
    width: 60px;
    height: 60px;

    &::before {
      font-family: inherit;
      font-size: inherit;
      opacity: 1;
    }
  }

  &.slick-prev {
    left: 0;
    background: url('../images/slick-left.svg') no-repeat;
    background-size: cover;
  }

  &.slick-next {
    right: 0;
    background: url('../images/slick-right.svg') no-repeat;
    background-size: cover;
  }
}

// Slick orverwrite

// Custom Checkbox
.custom__checkbox {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1A1F36;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .custom__checkbox--input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.custom__checkbox--check {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 4px;
  background-color: #fff;
  // border: 1px solid #000;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
    0px 0px 0px 1px rgba(60, 66, 87, 0.16),
    0px 1px 1px rgba(0, 0, 0, 0.12);
}

.custom__checkbox {
  .custom__checkbox--input:checked~.custom__checkbox--check {
    background-color: $purple;
  }
}

.custom__checkbox--check:after {
  content: "";
  position: absolute;
  display: none;
}

.custom__checkbox {
  .custom__checkbox--input:checked~.custom__checkbox--check:after {
    display: block;
  }

  .custom__checkbox--check:after {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// Custom Checkbox 

// Custom Checkbox Only
.custom__checkbox--only {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1A1F36;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 14px;
  height: 14px;

  .custom__checkbox--only-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:disabled {
      pointer-events: none;
      opacity: .8;
    }
  }
}

.custom__checkbox--only-check {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #dadbde;
}

.custom__checkbox--only {
  .custom__checkbox--only-input:checked~.custom__checkbox--only-check {
    background-color: $purple;
    border: 1px solid $purple;
  }
}

.custom__checkbox--only-check:after {
  content: "";
  position: absolute;
  display: none;
}

.custom__checkbox--only {
  .custom__checkbox--only-input:checked~.custom__checkbox--only-check:after {
    display: block;
  }

  .custom__checkbox--only-check:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// Custom Checkbox Only

// Radio
.custom__radio {
  display: inline-block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1A1F36;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .custom__radio--input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
}

.custom__radio--check {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: $white;
  border: 1px solid #dadbde;
  border-radius: 50%;

  &.lg {
    height: 22px;
    width: 22px;
  }
}

.custom__radio {

  .custom__radio--input:checked~.custom__radio--check {
    background-color: $white;
  }
}

.custom__radio--check:after {
  content: "";
  position: absolute;
  display: none;
}

.custom__radio {
  .custom__radio--input:checked~.custom__radio--check:after {
    display: block;
  }

  .custom__radio--check:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--purple);
  }

  &.lg {
    .custom__radio--check:after {
      width: 12px;
      height: 12px;
    }

  }
}

// Radio

// Radio Btn group
.custom__radio__group {
  position: relative;
  display: inline-block;

  .custom__radio__group--button {
    appearance: none;
    opacity: 0;
    position: absolute;
    z-index: -9999;

    &:checked+.custom__radio__group--label {
      color: $purple;
      // border: 1px solid #edc9fc;
      box-shadow: 0px 2px 5px rgba(200, 0, 255, 0.08), 0px 0px 0px 1px #4D1B6C, 0px 1px 1px rgba(200, 0, 255, 0.12);
    }
  }

  .custom__radio__group--label {
    font-weight: 500;
    font-size: 0.875rem;
    padding: 0.375rem 0.5rem;
    border-radius: 0.25rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #3C4257;
    background: #fff;
    // border: 1px solid #d2d6dc;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
      0px 0px 0px 1px rgba(60, 66, 87, 0.16),
      0px 1px 1px rgba(0, 0, 0, 0.12);
    margin: 0 6px;
    transition: all 0.2s ease;
  }
}

// Radio Btn group

// Antd Css overwrite
.ant-notification {
  .cm-notification {
    .ant-notification-notice-description {
      padding-right: 20px;
    }
  }
}

// Antd Css overwrite 


// Impersonate
.impersonate {
  background: #F7D9FF;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .impersonate__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .impersonate__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #C800FF;
    text-align: center;

    .impersonate__link {
      color: #C800FF;
      font-weight: 500;
      font-size: 14px;
      background: transparent;
      background-image: none;
      border: 0;
      padding: 0;
      text-decoration: underline;
    }
  }
}

// Impersonate

// Main
$header: 57px;
$impersonate: 50px;
$tabs: 38px;

.main {
  &[role="header"] {
    padding-top: 80px;
  }

  &[role="header-with-tabs"] {
    padding-top: 104px;

    &[impersonate="impersonate"] {
      padding-top: 104px + 50px;
    }
  }

  &[role="head"] {
    padding-top: 66px;
  }

  &[role="tabs"] {
    padding-top: 104px;
  }

  .main__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
}

// Main

.head {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 66px;
  background: $white;
  border-bottom: 1px solid #D9DCE1;

  &.head__fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .head__container {
    width: calc(100% - 30px);
    max-width: $maxWidth;
    margin: 0 auto;

    &.head__container--full {
      max-width: 100%;
    }
  }

  .head__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
  }

  .head__left {
    display: flex;
    align-items: center;
  }

  .head__close {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .btn__close {
      &:hover {
        background: #f2f2f2;
      }
    }
  }



  .head__divider {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    width: 1px;
    height: 24px;
    background: #D9DCE1;

    @media (min-width: 1024px) {
      margin: 0 20px;
    }
  }

  .head__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #1A1F36;
  }

  .head__right {
    display: inline-flex;
    align-items: center;

    .btn__head {
      white-space: nowrap;
      margin-left: 15px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

// Page header
.page__header {
  width: 100%;
  padding: 30px 0;

  // margin: 30px 0 20px;
  .page__header--container {
    width: calc(100% - 30px);
    max-width: $maxWidth;
    margin: 0 auto;
  }

  .page__header--row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: 35px;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .page__header--left {
    .page__header--title {
      font-family: $SFProDisplay;
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
      color: #1A1F36;
      margin-bottom: 0;

      @media (min-width: 768px) {
        font-size: 28px;
        line-height: 33px;
      }
    }

    .page__header--subtitle {
      font-family: $SFProDisplay;
      font-size: 18px;
      line-height: 22px;
      color: #1A1F36;
      margin: 12px 0 20px;

      @media (min-width: 768px) {
        font-size: 22px;
        line-height: 26px;
      }

      a {
        color: $pink;
        text-decoration: none;
      }
    }
  }

  .page__header--right {
    margin-top: 20px;

    @media (min-width: 768px) {
      margin-top: 0;
    }

    .btn {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      +.btn {
        margin-top: 20px;

        @media (min-width: 768px) {
          margin-top: 0;
        }
      }
    }
  }
}

// Page header

// Table 
.table__responsive--custom {}

table.table__custom {
  margin-bottom: 0;

  > :not(:first-child) {
    border-top: 0;
  }

  thead {
    border-bottom: 1px solid #E3E8EE;

    th {
      font-weight: 500;
      font-size: 13px;
      line-height: 13px;
      text-transform: uppercase;
      color: #1A1F36;
      padding: 0.75rem 0.5rem;
      white-space: nowrap;
    }
  }

  tbody {
    tr {
      &:hover {
        background: #fafafa;
      }

      td {
        font-weight: 500;
        font-size: 13px;
        line-height: 17px;
        color: #1A1F36;
        padding: 0.625rem 0.5rem;
        vertical-align: middle;
        white-space: nowrap;

        th {
          padding-bottom: 5px;
          font-weight: 500;
          font-size: 13px;
          text-transform: uppercase;
          text-align: -webkit-match-parent;
        }

        tr {
          font-weight: 500;
          font-size: 13px;
        }
      }
    }
  }

  .table__selection--column {
    width: 20px;
  }

  .table__cell--first {
    width: 20%;
  }

  .table__cell--last {
    text-align: right;
  }

  .table__status {
    &.active {
      color: $secondaryColor;
    }

    &.inactive {
      color: $thirdColor;
    }
  }
}

.photos-video-thumb {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  object-fit: cover;
  object-position: center;
  margin-right: 15px;
}

.items-order-table {
  tr {
    position: relative;

    th,
    td {
      &:first-child {
        padding-left: 30px;
      }
    }
  }

  .reorderIcon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

// .table-description {
//   display: -webkit-box;
//   -webkit-line-clamp: 1;
//   -webkit-box-orient: vertical;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

.table__blank {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .table__blank--text {
    color: #697386;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
}

// Table 

// Table Pagination
.table__pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 25px 0;

  .table__pagination--results {}

  .table__pagination--nav {

    .table__pagination--prev,
    .table__pagination--next {
      margin-left: 8px;

      &:hover {
        color: var(--bs-btn-active-color);
        background-color: var(--bs-btn-active-bg);
        border-color: var(--bs-btn-active-border-color);
      }
    }
  }
}

// Table Pagination

// Base 


// React Modal Css
.ReactModal__Body--open {
  overflow: hidden;
  // padding-right: 17px;
}

.ReactModal__Body--open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.modal {
  background: rgba(0, 0, 0, 0.5);
}

.ReactModal__Overlay--after-open.modal {
  display: block;
}


.react-modal {
  .modal-content {
    border: 0;
    border-radius: 10px;

    &.modal-content-flat {
      border-radius: 0;
    }
  }

  .modal-dialog {
    max-width: 600px;

    &.react-modal-dialog-xs {
      max-width: 420px;
    }

    &.react-modal-dialog-sm {
      max-width: 700px;
    }

    &.react-modal-dialog-sm-md {
      max-width: 850px;
    }

    &.react-modal-dialog-md {
      max-width: 960px;
    }

    &.react-modal-dialog-lg {
      max-width: 1024px;
    }

    &.react-modal-dialog-xl {
      max-width: 1200px;
    }
  }
}

.react-modal-header {
  padding: 20px;
  position: relative;

  &.border-bottom {
    border-bottom: 1px solid var(--light-gray);
  }

  .react-modal-title {
    font-family: $SFProDisplay;
    font-weight: $bold;
    font-size: 16px;
    line-height: 22px;
    color: $lightblack;
    margin-bottom: 0;

    &.react-modal-title-style-2 {
      color: #000000;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      margin-bottom: 10px;
    }
  }

  .react-modal-subtitle {
    font-size: 16px;
    line-height: 25px;
    color: $black;
    margin-bottom: 0;

    &.react-modal-subtitle-style-2 {

      font-weight: 400;
      font-size: 17px;
      line-height: 26px;
      color: #000000;
    }
  }

  .react-modal-close {
    position: absolute;
    top: 18px;
    right: 15px;
    z-index: 1;
  }
}

.react-modal-body {
  padding: 20px;
  background: #F7FAFC;

  &.react-modal-body-style-2 {
    background: #fff;
  }
}

.react-modal-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.react-modal-body-text {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.react-modal-footer {
  padding: 20px;
  border-radius: 0 0 10px 10px;
  background: $white;
  display: flex;
  justify-content: flex-end;

  .btn {
    margin-left: 20px;
  }
}

// React Modal Css

// Genral Css

// material-Lable style
.material-textfield {
  position: relative;

  .material-textfield-label {
    font-size: 14px;
    // line-height: normal;
    color: #000000;
    position: absolute;
    pointer-events: none;
    left: 14px;
    top: 16px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    margin-bottom: 0;
  }

  .material-textfield--input {

    &:focus,
    // &:read-only,
    &:disabled,
    &:valid {
      padding-top: 20px;
    }

    // &:read-only ~ .material-textfield-label,
    &:disabled~.material-textfield-label,
    &:valid~.material-textfield-label,
    &:focus~.material-textfield-label {
      top: 8px;
      left: 14px;
      font-weight: $bold;
      font-size: 12px;
      // line-height: normal;
      color: $black;
    }

    &:disabled~.material-textfield-label {
      color: #9B9B9B;
      top: 8px;
      font-size: 14px;
      // line-height: normal;
      font-weight: 700;
    }
  }
}

// material-Lable style

// Mac Window
.mac__window {
  width: 100%;
  display: block;

  .mac__window--header {
    background: #E3F0F3;
    width: 100%;
    padding: 8px 12px;
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: center;

    .mac__window--dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #f9f9f9;
      border-radius: 50%;
      margin: 0 5px 0 0;

      &.dot__1 {
        background: #FF64FF;
      }

      &.dot__2 {
        background: #C800FF;
      }

      &.dot__3 {
        background: #00E1E1;
      }
    }
  }

  .mac__window--body {
    position: relative;
    background: $white;
    width: 100%;

    img {
      width: 100%;
      max-width: 100%;
    }
  }

  .mac__window--inner {
    width: 100%;
    height: calc(100vh - 253px);

    .preview__default {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

// Mac Window

// Tabs
.tabs__links {
  display: flex;
  align-items: center;
  position: relative;
  background: $white;
  border-bottom: 1px solid #E5E5E5;
  width: calc(100% - 30px);
  max-width: $maxWidth;
  margin: 0 auto;

  &.tabs__links--space {
    margin-bottom: 20px;
  }

  &.tabs__links--full {
    width: 100%;
  }

  .tabs__link {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #697386;
    background: transparent;
    background-image: none;
    text-decoration: none;
    position: relative;
    padding: 9px 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0;
    border-bottom: 2px solid transparent;
    margin-right: 26px;

    &:hover,
    &:focus {
      outline: none;
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: $purple;
      border-bottom: 2px solid $purple;
    }
  }
}

.tabs__underline {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 22px;

  .btn__tab {

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #697386;
    background: transparent;
    background-image: none;
    text-decoration: none;
    position: relative;
    padding: 9px 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid transparent;
    margin-right: 26px;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: $black;
      border-bottom: 2px solid $black;
    }
  }
}

// Tabs

// Dropzone
.dropzone {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  .dropzone__area {
    background: $white;
    border: 1px dashed #D9DCE1;
    border-radius: 5px;
    width: 100%;
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.dropzone__grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  .dropzone__grid--sorter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .dropzone__grid--item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    margin-bottom: 15px;
    position: relative;

    &:last-child {
      margin-right: 0;
    }
  }
}

.dropzone__grid--preview {
  position: relative;
  width: 100%;
  height: 50px;
  background: #d2d6dc;
  border-radius: 4px;

  >.preview__thumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    display: block;
    // position: absolute;
    // top: 0;
    // left: 0;
  }
}

.dropzone__grid--remove {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  .btn__remove {
    line-height: normal;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    padding: 4px;
    border-radius: 100px;
    position: absolute;
    z-index: 1;
    background: #f04533;

    // background: #000;
    svg {
      position: absolute;
      z-index: -1;
      pointer-events: none;
    }
  }
}

// Dropzone

// Crop Area
.editor__crop {}

.react__crop {
  width: 100%;
  height: 100%
}

.react__crop--slider {}

.editor__crop--action {}

// Crop Area

// Custom Container
.custom__container {
  .custom__wrap {
    width: 100%;
    max-width: 415px;
    margin: 0 auto;
    padding: 20px 0;

    @media (min-width: 1024px) {
      padding: 40px 0;
    }
  }
}

// Custom Container

// Login / Signup
.signup__main,
.login__main {

  .signup__left-right-main,
  .login__left-right-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: calc(100vh - 66px);

    @media (min-width: 1024px) {
      flex-direction: row;
    }

    .left__view,
    .right__view {
      display: flex;
      align-items: center;
    }

    .left__view {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 20px;
      background: #F7FAFC;

      @media (min-width: 1024px) {
        padding: 70px;
        width: 100%;
      }
    }

    .right__view {
      width: 100%;

      @media (min-width: 1024px) {
        width: 100%;
        max-width: 520px;
      }
    }
  }

  .view__wrapper {
    width: 100%;
    padding: 25px 20px;

    @media (min-width: 1024px) {
      padding: 25px 50px;
    }
  }

  .view__wrapper--cta {}

  .view__wrapper--footer {
    font-size: 0.875rem;
    padding: 15px 0;
    text-align: center;
  }

  .signup__head {
    margin-bottom: 25px;

    .appsumo {
      margin-bottom: 15px;
    }

    h2 {
      font-family: $SFProDisplay;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #1A1F36;
      margin-bottom: 10px;
    }

    p {
      font-family: $SFProDisplay;
      font-size: 14px;
      line-height: 17px;
      color: #1A1F36;
      margin-bottom: 0;
    }
  }

  .signup__info {
    margin-bottom: 20px;

    h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #1A1F36;
      margin-bottom: 18px;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #1A1F36;
      margin-bottom: 0;
    }
  }
}

// Login / Signup

// Top Navigation
.top__navigation {
  display: flex;
  align-items: center;
  position: relative;
  background: #FAFAFA;
  padding: 15px 0;
  width: 100%;

  &.top__navigation--fixed {}
}

.top__navigation--container {
  width: calc(100% - 30px);
  max-width: $maxWidth;
  margin: 0 auto;
}

.top__navigation--row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .top__navigation--left {
    display: flex;
    align-items: center;

    .top__navigation--logo {
      margin-right: 25px;

      .top__navigation--brand {
        .header-logo {
          height: 34px;
          width: auto;
        }

        >img {
          max-width: 100px;
        }
      }
    }
  }

  .top__navigation--right {
    .top__navigation--nav {
      display: flex;
      align-items: center;

      .nav__link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-left: 15px;
        padding: 0.3125rem;
      }
    }
  }
}

.top__navigation--dropdown {
  top: 56px !important;
  .profile__wrapper {
    padding: 12px;
    border-bottom: 1px solid #D9DCE1;
    min-width: 200px;

    .profile__name {
      color: $black;
      font-size: 14px;
      font-weight: 600;
    }

    .profile__role {
      font-size: 12px;
    }
  }

  .top__navigation--dropdown-link,
  .top__navigation--dropdown-btn {
    text-decoration: none;
    font-size: 14px;
    color: $pink;
    font-weight: 600;

    &:hover {
      color: $pink;
    }
  }

}

// Top Navigation

// Trial Period
.trial__period {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }

  .trial__period--btn {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #C800FF;
    background: #F7D9FF;
    border: 0;
    background-image: none;
    border-radius: 100px;
    padding: 6px 15px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &.disabled,
    &:disabled {
      pointer-events: none;
      opacity: .5;
      user-select: none;
    }
  }
}

// Trial Period

// Billing
.billing__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #D9DCE1;

  .billing__header--title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #1A1F36;
    margin-bottom: 0;
  }
}

.payment__card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #D9DCE1;
  margin-bottom: 30px;

  .payment__card--info {
    display: flex;
    align-items: center;
  }

  .payment__card--card {
    margin-right: 12px;
  }

  .payment__card--number {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }

  .payment__card--update {
    display: flex;
    align-items: flex-end;
  }
}

.blank__state {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .blank__state--text {
    color: #697386;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
  }
}

// Billing

// Domain Setup
.domain__setup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px 0;

  .btn__domain {
    margin: 0 9px;
  }
}

// Domain Setup

.property__type--list {
  @include list-style-none;

  >li {
    padding: 10px 0;
    border-bottom: 1px solid #D9DCE1;

    &:last-child {
      border-bottom: 0 solid #D9DCE1;
    }
  }
}

.property__features--list,
.building__features--list {
  @include list-style-none;
  display: flex;
  flex-wrap: wrap;

  >li {
    padding: 10px 0;
    width: calc(100% / 2);
  }
}

// Editor 

.ql-container {
  min-height: 150px;
  max-height: 150px;
  overflow: hidden;
  overflow-y: auto;
}

.editor__sidebar {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
}

.editor__sidebar--left {
  width: 100%;
  height: calc(100vh - 132px);
  overflow: auto;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1024px) {
    max-width: 500px;
    height: calc(100vh - 66px);
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #A3ACB9;
    // outline: 1px solid slategrey;
  }

  &.is__edit {
    @media (max-width: 1024px) {
      display: block;
    }
  }
}

.editor__sidebar--left-wrapper {
  padding: 20px 20px;
  width: 100%;

  @media (min-width: 1024px) {
    padding: 20px 40px;
  }
}

.editor__sidebar--right {
  flex: 1;
  width: 100%;
  background: #F7FAFC;
  height: calc(100vh - 66px);

  @media (max-width: 1024px) {
    display: none;
  }

  @media (min-width: 1024px) {
    height: calc(100%);
  }

  &.is__preview {
    @media (max-width: 1024px) {
      display: block;
    }
  }
}

.editor__sidebar--right-wrapper {
  padding: 20px;
  width: 100%;
  height: calc(100vh - 132px);

  @media (min-width: 1024px) {
    max-width: 1340px;
    margin: 0 auto;
    padding: 50px 20px;
    height: calc(100vh - 66px);
  }
}

.editor__sidebar--footer {
  display: flex;
  align-items: center;
  padding: 15px 0;
  border-top: 1px solid #D9DCE1;

  @media (min-width: 1024px) {
    display: none;
  }

  .btn {
    width: 100%;
    margin: 0 10px;
  }
}

.btn-eye-disabled {

  // pointer-events: none;
  .eye-icon {
    path {
      fill: #D8D8D8;
    }
  }
}


input[type=search]::-webkit-search-cancel-button {
  display: none;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  opacity: 0.5;
  color: #3C4257;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
}

// Editor 

// Preview Template
.preview__header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 18px;

  .preview__header--row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .preview__header--left,
  .preview__header--right {}

  .preview__header--title {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #1A1F36;
    margin-bottom: 0;
    text-align: center;

    @media (min-width: 1024px) {
      text-align: left;
    }
  }

  .preview__header--subtitle {
    font-size: 12px;
    line-height: 14px;
    font-style: italic;
    color: #1A1F36;
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;

    @media (min-width: 1024px) {
      text-align: left;
    }
  }

  .preview__header--action {
    display: flex;
    align-items: center;
    margin-top: 20px;

    @media (min-width: 768px) {
      margin-top: 0;
    }

    .btn__desktop,
    .btn__mobile,
    .btn__fullscreen {
      margin-left: 25px;

      &:first-child {
        margin-left: 0;
      }

      &.active {
        svg {
          path {
            fill: #C800FF;
          }
        }
      }
    }
  }
}

.preview__window {}

.editor__webisite--preview {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  transform: scale(1);
  width: 100%;
  height: calc(100% - 66px);

  .responsive__scale--wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: block;
    // margin: 0 auto;
    transform-origin: top left;
    transition: all .2s ease-in-out;
    transform: scale(1);

    &.is__desktop {
      width: 1340px;
      height: 857px;
    }

    &.is__mobile {
      width: 375px;
      height: calc(667px - 98px);

      @media (min-width: 768px) {
        width: 375px;
        height: 667px;
      }
    }

    .preview__window {
      width: 100%;
      height: 100%;
    }
  }

  .mac__window {
    height: 100%;

    .mac__window--body {
      height: 100%;
    }
  }

  .preview__website--iframe {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    padding: 0;
    border: 0;
    background-color: #fff;
    z-index: 1;
    overflow: auto;
  }
}

// Preview Template

// Demo Template
.demo__preview {}

// Demo Template

.pricing__module {
  padding: 0 0 15px;

  .pricing__module--inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pricing__module--save-money {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #C800FF;
    background: rgba(200, 0, 255, 0.1);
    border-radius: 100px;
    padding: 2px 10px;
    margin: 10px 0 15px;
    margin-left: calc(100% - 296px);
  }

  .pricing__module--text {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
  }
}

// Coupon Code
.coupon__code {
  position: relative;
  display: flex;
  align-items: center;

  .coupon__code--btn {
    margin-left: 15px;
  }
}

.powered__by__stripe {
  margin-top: 10px;
  max-width: 120px;
}

// Coupon Code

// Image Picker
.image__picker {
  background: #F7FAFC;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 280px;

  .image__picker--thumb {
    width: 100%;
    height: 0;
    position: relative;
    padding-top: 66.66%;

    >img {
      position: absolute;
      top: 0;
      left: 0;
      object-position: center;
      object-fit: contain;
      padding: 10px;
      width: 100%;
      height: 100%;
      max-width: 100%;
      display: block;
    }
  }

  .image__picker--controls {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #D9DCE1;

    .btn {
      width: 100%;
      border: 0;
      border-radius: 0;
      border-right: 1px solid #D9DCE1;

      &:last-child {
        border-right: 0 solid #D9DCE1;
      }
    }
  }
}

// Image Picker

// Ck Editor 5
.ck__editor--customized {
  .ck {
    &.ck-toolbar {
      border: 1px solid #D9DCE1;
      background: $white;
      border-radius: 4px 4px 0 0 !important;
    }
  }

  .ck-editor__editable {
    border: 1px solid #D9DCE1 !important;
    border-top: 0 !important;
    border-radius: 0 0 4px 4px !important;
    min-height: 300px;
    max-height: 300px;

    &:focus {
      box-shadow: none !important;
    }
  }
}

// Ck Editor 5


// Phone Moduale
.react-tel-input {
  .form-control {
    height: 44px;
    width: 100%;
  }
}

// Phone Moduale


// General comp start

.form-list-item {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0;

    &:not(:last-child) {
      border-bottom: 1px solid var(--light-gray);
    }
  }
}

.inline-remove-field {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    margin-bottom: 0;
    line-height: normal;
  }
}

.stack-button-container {
  text-align: center;

  button:not(:last-child) {
    margin-bottom: 24px;
  }
}

.modal-list-container {
  margin: 0 -20px;
  max-height: 300px;
  overflow: auto;

  &.h-md {
    min-height: 300px;
  }

  .modal-list-items {
    border-bottom: 1px solid var(--light-gray);
    padding: 0 20px;
    margin-bottom: 15px;
    padding-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.answers-option-container {
  position: relative;

  .question-small-heading {
    position: absolute;
    top: 0;
    right: 5px;
    display: inline-block;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    color: var(--tertiary);
  }
}

.question-options {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  position: relative;

  .form-group {
    margin-bottom: 0;
    width: calc(100% - 80px);
  }

  .form--inline-radio {
    flex: 1;
    text-align: center;
  }
}

.speakers--row {
  .speakers--column {
    &:not(:last-child) {
      border-bottom: 1px solid var(--light-gray);
      margin-bottom: 20px;
    }
  }
}

.avatar-upload-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatar-upload-btn {
  position: absolute;
  bottom: -0px;
  right: -0px;
  height: 17px;
  width: 17px;
  border-radius: 50px;
  background-color: var(--purple);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  svg {
    width: 11px;
  }
}

.user-avatar-block {
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: #D4D8DF;
  display: flex;
  align-items: center;
  justify-content: center;

  .text-block {
    position: relative;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .avatar-img {
    border-radius: 50px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

// General comp end



// Add/Edit Agenda 

.mb-small-15 {
  margin-bottom: 15px;
}

.label-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

// Add/Edit Agenda End 


// home thmes wrapper Css::Start 
.home__thmes-wrapper {}

// home thmes wrapper Css::End
.all__slect {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 9px;

  .custom__checkbox {
    margin-top: -17px;
  }

  .btn {
    margin-left: 18px;
  }
}

table {
  .form-group {
    margin-bottom: 0px;
    width: 320px;
  }
}

.react-select__placeholder,
.react-select__indicators {
  display: none !important;
}

// .tag__group > div > div > div:first-child > div > div{
//   background-color: #4D1B6C;
// }
// .tag__group > div > div > div:first-child > div > div > div{
//   color: #fff;
//   font-weight: 400;
//   font-size: 12px;
// }

.tag__group {
  .react-select__multi-value__remove {
    padding: 2px 5px;

    &:hover {
      background-color: transparent;
      color: #fff;
    }
  }


  // .css-1s2u09g-control,.css-1pahdxg-control{
  //   border-color: #D8D8D8;
  //   border-radius: 4px;
  //   padding: 5px 0;
  //   box-shadow: none;
  //   min-height: 44px;
  //   min-width: 160px;
  // } 

  .react-select__multi-value {
    padding: 2px 2px;
    color: #fff;
    background: #4D1B6C;
    border-color: #4D1B6C;
    border-radius: 4px;

    .react-select__multi-value__label {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #fff;
    }
  }

  .css-1pahdxg-control:hover {
    border-color: #D8D8D8;
    box-shadow: none;
  }

  // .css-xb97g8:hover{
  //   background-color: transparent;
  //   color: #fff;
  // }

  // Ant input tage End
  .react-select__control {
    border-color: #D9DCE1 !important;
    border-radius: 5px !important;
    padding: 5px 0;
    box-shadow: none;
    min-height: 44px;
    min-width: 160px;
  }

  .ant-select-multiple .ant-select-selection-item {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    padding: 2px 3px;
    color: #fff;
    background: #4D1B6C;
    border-color: #4D1B6C;
    border-radius: 4px;
  }

  .ant-select-selection-item-remove {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

// Start
.viewother__news-wrapper {
  .all__slect {
    margin-top: 15px;
  }

  .table__custom {
    .table-description {
      white-space: inherit;
      // min-width: 382px;
      min-width: 350px;
    }

    // .custom__checkbox{
    //   font-weight: 400;
    //   font-size: 12px;
    //   line-height: 14px;
    //   color: #1A1F36;
    // }
    tr {
      td {
        vertical-align: top;
      }
    }
  }
}

.edit-btn {
  text-decoration: underline;
  color: #4D1B6C;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  border: none;
  background-color: transparent;
  padding: 0;

  &:disabled {
    opacity: 0.5;
    text-decoration: none;
  }
}

// End

// addcurated list modal Css::Start
.addcurated__list-modal {}

.send__other-news-modal {
  .form--inline-radio {
    margin-bottom: 15px;
  }

  .custom__radio {
    line-height: 24px;
    padding-left: 40px;
  }

  .send__email-input {
    margin-top: 0px;
  }

  .list {
    list-style: none;
    padding-left: 2px;
    max-height: 200px;
    overflow: auto;
    margin-bottom: 0px;

    .items {
      margin-bottom: 20px;
    }
  }

  .curated__list-section {
    .label-primary {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      border-bottom: 1px solid #000000;
      margin-bottom: 20px;
      padding-bottom: 7px;
      width: 100%;
    }
  }
}

// addcurated list modal Css::End



.list-custom-block {
  margin-bottom: 20px !important;

  li:last-child {
    margin-bottom: 0 !important;
  }
}

table.table__custom {}

.table__status .text-block {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  trasition: all 0.2s ease-in-out;
}

.ck-content {
  max-height: 400px;
  min-height: 233px;
  background-color: #fff;
  // border: 2px solid #E5E5E5 !important;
}

.ck.ck-list {
  max-height: 300px;
  overflow: auto;
}

.ck.ck-list__item .ck-button .ck-button__label {
  line-height: normal !important;
}

.custom__container .ck-editor__editable {
  border: 1px solid #D7D7D7;
  max-height: 392px;
  min-height: unset;
}

.custom__container .ck.ck-editor__editable_inline {
  border: 1px solid #D7D7D7;
}

.dropzone-file {
  border-radius: 5px;
  border: 1px dashed #D9DCE1;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.csv-reader-uploader {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;

  input {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
}

.upload-content {
  height: 50px;
  width: 50px;
  position: relative;
  margin-top: 15px;

  button {
    height: 15px;
    width: 15px;
    padding: 5px;
    font-size: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F04533;
    position: absolute;
    top: -5px;
    right: -5px;
    border-radius: 50px;
    border: none;
  }

  img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    object-position: center;
  }
}

.add-btn {
  text-decoration: underline;
  color: #4D1B6C;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  border: none;
  background-color: transparent;
  padding: 0;
}

a:hover {
  color: #4D1B6C;
}

.remove-btn {
  text-decoration: underline;
  color: #ec4e0f;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  border: none;
  background-color: transparent;
  padding: 0;
}

.label-container-inline-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;

  label {
    margin-bottom: 0;
  }
}

.table-list-container {
  min-width: 350px;
  max-height: 173px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid #D9DCE1;
  border-radius: 4px;
  padding: 15px;
  white-space: normal;
  margin-bottom: 15px;

  ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      gap: 15px;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      .btn-container-item {
        display: flex;
        gap: 15px;
        justify-content: space-between;

        button {
          min-width: 90px;
        }
      }
    }
  }
}

.table-editor-block .ck-editor__editable {
  max-height: 75px;
}


.table-radio-container-list {
  padding-right: 10px;

  .custom__radio__group {
    min-width: 100px;
    border: 1px solid #D9DCE1;
    border-radius: 4px;
    white-space: normal;
    margin-bottom: 15px;
    overflow: hidden;

    .form--inline-radio {
      padding: 15px 10px;

      .custom__radio {
        padding-left: 35px;
        padding-top: 2px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #D9DCE1;
      }
    }
  }
}


.overlayflow-y-auto {
  overflow-y: visible;
}



.textarea-select .react-select__control {
  min-height: 100px;
  align-content: flex-start;
}

.loader__save {
  margin-left: 5px;
  transform: scale(0.8);

  .anticon-loading {
    color: #000 !important;
  }
}

.table-primary-block {
  position: relative;
  background: $purple;
  padding: 10px;
  color: $white;

  .tpb-child {
    text-align: center;

    h5,
    h6 {
      color: #fff;
    }
  }
}

.word__table_break .table__custom tr td {
  word-break: break-word;
  white-space: normal;
}

.word__table_break .table__custom tr td .text-btn {
  color: var(--purple);
  font-weight: 600;
  text-align: left;
  background: transparent;
  border: none;
  text-decoration: underline;
  padding: 0;
  line-height: normal;
}


.blue-icon {
  path {
    fill: #4d1b6c;
  }
}

.inner-tabs-container {
  width: calc(100% - 30px);
  max-width: 1440px;
  margin: 0 auto;

  button,
  a.tabs__link {
    border-radius: 50px;
    padding: 0;
    border: none;
    color: #697386;
    font-weight: 500;
    background: transparent;
    font-size: 12px;
    padding: 5px 10px;
    text-decoration: none;

    &.active {
      color: $purple;
      background: #e8d9f3;
    }
  }
}

.table-other-news {
  &.table__custom tbody tr td {
    white-space: normal;
  }
}

.table-fixed {
  table-layout: fixed;
}

.text-btn.word-break {
  white-space: normal;
}

.removeBorder li {
  border-bottom: none !important;
  padding: 12px 0;
}

.purple-text a,
.theme-text-color {
  color: $purple !important;
}
.other-news-links a {
  white-space: pre-line;
  max-width: 240px;
  word-break: break-all;
  display: inline-block;
}
.theme-Link a {
  max-width: 400px !important;
}

.sort-btn {
  padding: 0;
  background-color: transparent;
  border: none;

  .anticon {
    display: inline-block;
    vertical-align: 2px;
    margin-left: 5px;
  }
}

.content-hide {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.read-btn {
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--purple);
  font-weight: 700;
  margin-top: 5px;
}

.editor-page--container .ck-content p {
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

.sticky-header-container {
  position: sticky;
  top: 64px;
  background-color: #fff;
  z-index: 9;
  padding-top: 15px;
  padding-bottom: 10px;
}

.ck-editor-updates {
  .ck-editor__editable {
    min-height: 320px;
  }
}

.socket-effect-toolip {
  .ant-tooltip-arrow {}

  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: red;
  }

  .ant-tooltip-inner {
    background-color: red;
  }
}

.socket-title {

  color: #fff;
  background-color: red;
  padding: 5px 10px;
  border-radius: 6px 6px 0 0;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -29px;
  left: -3px;
}

// 18-08-2023 css open
.custom-label {
  font-family: 'SF Pro Text';
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.min-width-select {
  width: 122px;
  font-size: 14px;
}

.x-hidden {
  overflow-x: hidden;
}

// 18-08-2023 css close



.poll-wrapper {
  max-width: 750px;
  width: 100%;
  margin: 56px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.save-purplebtn {
  min-width: 330px;
  min-height: 50px;
  display: block;
  color: #fff;
  background-color: #4D1B6C;
  border-color: #4D1B6C;
}

.mb-42 {
  margin-bottom: 42px;
}

.pole-form-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 0 auto 0;
  list-style: none;
  padding: 0;

  li {
    width: 100%;
    margin-bottom: 24px;

    label {
      color: #1A1F36;
      font-family: SF Pro Text;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }

    .input-style {
      width: calc(100% - 140px);
      margin-right: 20px;
      height: 44px;
      padding: 13.5px 12px 13.5px 16px;
      outline: none;
      border-radius: 5px;
      border: 1px solid var(--Grey-300, #D9DCE1);
      background: #FFF;
      font-family: SF Pro Text;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .action-bars {
      width: 105px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .textara-style {
      width: 752px;
      height: 120px;
      padding: 13.5px 12px 13.5px 16px;
      outline: none;
      border-radius: 5px;
      border: 1px solid var(--Grey-300, #D9DCE1);
      background: #FFF;
    }

    .TrashBtn {
      border: 0;
      background-color: transparent;
      margin-left: -20px;
    }
  }
}

.answer-heading {
  color: #4D1B6C;
  font-size: 23px;
  font-weight: 500;
  line-height: normal;
  margin: 0 0 14px 0;
}

.right-answer {
  display: flex;
  align-items: center;

  .right-answer-graph {
    width: 338px;
    height: 37px;
    background: #4D1B6C;
  }

  p {
    color: #4D1B6C;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    padding: 0 0 0 20px;
  }
}

.wrong-answer {
  display: flex;
  align-items: center;

  .wrong-answer-graph {
    width: 180px;
    height: 37px;
    background: #D5CADD;
  }

  p {
    color: #4D1B6C;
    // font-family: SF Pro Text;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    padding: 0 0 0 20px;
  }
}

.errors-msg {
  position: relative;
  top: -20px;
}


.pole-form-list {

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked+label,
  [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #333;
    font-family: "Graphik";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

  }

  [type="radio"]:checked+label:before,
  [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: -6px;
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    border-radius: 100%;
    background: #fff;
  }

  [type="radio"]:checked+label:after,
  [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 20px;
    height: 20px;
    background: #4D1B6C;
    border: 1px solid #4D1B6C;
    position: absolute;
    top: -6px;
    left: 0px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.margtop-minus {
  margin-top: -8px;
}
.img-body{
  width: 100%;
  aspect-ratio: 1/1;
  outline-color: red;
  display: inline-block;
  height: 92vh;
}
.img-body img {
  display: flex;
  margin: 0 auto;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.slick-next {
  display: none;
}
.slick-prev {
  display: none;
}

.image-modal {
  max-width: 90%;
  margin: auto;
  height: 92vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  .modal-content {
    height: 100%;
    border-radius: 0;
  }
  .head__close {
    position: absolute;
    z-index: 1;
    right: 0;
  }
  .left-arrow {
    position: absolute;
    top:50%;
    transform: translateX(-50%);
    left: 28px;
    background: #fff;
    max-width: 40px;
    height: 40px;
    width: 100%;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

   }
  .right-arrow {
    position: absolute;
    top:50%;
    transform: translateX(-50%);
    right: -10px;
    background: #fff;
    max-width: 40px;
    height: 40px;
    width: 100%;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
 cursor: pointer;
  }
}

.white-bg {
  background: #fff;
}
.theme-img-width {
  max-width: 100px;
}
.live-checkbox {
  padding: 10px 0 0 22px;
}
.purple-loader {
  .anticon-spin {
    color: #4D1B6C !important;
  }
}
.add-btn-width {
  min-width: 125px;
}

.other-news-radio {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  border: 1px solid #D9DCE1;
  border-radius: 0.3125rem;
  padding: 20px 18px 6px 18px;
  .custom__radio {
    width: 50%;
    margin-bottom: 14px;
  }
}
.text-justify-custom {
  text-align: justify;
}
.primarySecondaryTag {
  p {
    margin: 0;
    color: #4D1B6C;
    width: 100%;
    text-transform: uppercase;
    font-size: 11px;
  }
  span {
    color: #1A1F36;
    margin-bottom: 20px;
    display: block;
    font-size: 12px;
    
  }
}
.minWidthSave {
  min-width: 60px;
}
.customSize{
  .anticon-spin {
    font-size: 16px !important;
  }
}
.minWidthCreateTagSave {
  min-width: 120px;
}
.uploadlistbuttonmin{
  min-width: 94px;
}
.ExportListLoader{
  min-width: 135px;
  min-height: 34px;
}
.SubmitSave {
  min-width: 65px;
}
.themeSave {
  min-width: 50px;
}
// .label-primary-width {
//  width: calc(100% - 130px);
// }
// .label-primary-btns {
//   width: 130px;
//   display: flex;
//   justify-content: flex-end;
// }