// Custom
$SFProDisplay: 'SF Pro Display';
$SFProText: 'SF Pro Text';
// Font Family

// Color
$purple: #4D1B6C;
$secondaryColor: #5ABDCE;
$thirdColor: #F04533;
$pink: #F57B8C;
$green-logo: #00E1E1;
$green: #4BB543;
$lightgreen: #F7FAFC;
$white: #fff;
$black: #000;
$lightblack: #1A1F36;
$red: #EA1414;
$gray: #979797;
$lightgray: #D9DCE1;
// Color

// font Weight
$xlbold: 900;
$exbold: 800;
$bold: 700;
$semibold: 600;
$medium: 500;
$normal: 400;
// font Weight

$base: '../images/';
$fontbase: 1rem;

@mixin list-style-none {
  list-style: none;
  padding: 0;
  margin: 0;
}

// Max width
$width: 100%;
$maxWidth: 1440px;
// Max width


:root {
  --primary: #fff;
  --secondary: #000;
  --tertiary: #1A1F36;
  --purple: #4D1B6C;
  --gray: #697386;
  --orange: #F04533;
  --gray-light: #979797;
  --light-gray: #D9DCE1;
  --text-gray: #757679;
  --error: #EA1414;
  --success: #4BB543;
  --delete: #E17E8B;
}